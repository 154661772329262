@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  padding: 0;
  background-color: white;
}

:root {
  --gray-150: #3d4467;
}

.gray-50 {
  color: #c3c5ce;
}

.gray-90 {
  color: #5e6379;
}

.gray-100 {
  color: #545b79;
}

.default-color {
  color: #545b79;
}

.bg-black-100 {
  background-color: #eeeded;
  border: 0;
}

/* Button hover effect */
#back-to-top-btn:hover {
  background-color: #6b8b65; /* Change background color on hover */
}

.title {
  width: fit-content;
  max-width: 710px;
  margin: 0 0 30px;
  font-size: 48px;
  color: white;
}

.w-min-content {
  width: min-content;
}

/* Start swiper style */

.swiper {
  width: 100%;
  height: fit-content;
  padding-bottom: 35px;
}

.swiper-slide {
  font-size: 18px;
  margin-right: 15px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-pagination-bullet {
  background-color: #43766c;
}

.swiper-button-next,
.swiper-button-prev {
  color: #43766c;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px;
  padding: 10px 15px;
  background-color: #ffffff;
  border-radius: 50%;
}

.swiper-button-next:after {
  margin-right: 15px;
}

/* End swiper style */

/* Start header */

.header .img-web {
  width: 100%;
}

.header .img-mobile {
  width: 100%;
}

.header .btn-agen {
  width: 60%;
  background-color: white;
  border: 2px solid rgb(65, 3, 3);
  background-color: red;
  border-radius: 30px;
  box-shadow: rgb(65, 3, 3);
  color: white;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.header .btn-agen:hover {
  background-color: red;
}

.header .btn-agen:active {
  box-shadow: white 2px 2px 0 0;
  transform: translate(2px, 2px);
}

/* end header */

/* Start media query */

/* Small */
@media (max-width: 768px) {
  .header .btn-agen {
    width: 100%;
  }
}

/* medium */

@media (min-width: 768px) {
}

@media (max-width: 992px) {
}

/* Large */

@media (min-width: 1198px) {
}

/* End media query */
